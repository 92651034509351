// Core Partials
@import '@core/styles/theme';
@import '@core/styles/tooltips';
@import '@core/styles/tippy';
@import '@core/styles/mixins/dark-mode';
@import '@core/styles/mixins/markdown-overrides';
@import './codemirror';

body {
  @include dark-mode {
    color: var(--color-text-default);
  }

  background-color: var(--color-bg-page);
  color: var(--color-text-default);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// Application Layout
.App {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);

  .rm-Container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--container-lg);
    width: 100%;

    &Narrow {
      max-width: var(--container);
    }

    &_flex {
      display: flex;
    }
  }

  // Only if reference main exists, otherwise we’ll be pushing down angular views
  // this also means the logo gets cut-off when the docs content is > 100vh
  // will revisit when we work on guides to make this all consistent
  .rm-Header + .rm-ReferenceMain {
    min-height: calc(100vh - 90px); // 90px = header height
  }

  .rm-LandingPage,
  .rm-SuggestedEdits,
  .rm-SuggestionDiff,
  .rm-Guides,
  .rm-Recipes,
  .rm-Recipes-modal,
  .rm-ReferenceMain,
  .rm-Changelog,
  .rm-Discuss,
  .rm-CustomPage {
    @include markdown-overrides;

    color: var(--color-text-default);
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 1.5;

    // except in tooltips…
    .rm-Tooltip h1,
    .rm-Tooltip h2,
    .rm-Tooltip p {
      color: var(--white);
    }

    // override so references are with guides
    header h1 {
      font-size: 1.9em;
      font-weight: var(--markdown-title-weight);
      line-height: 1.2;
    }

    a {
      @include dark-mode {
        color: var(--color-text-default);
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }

    .rm-Tooltip {
      color: var(--white);
    }

    .rm-Article {
      box-sizing: border-box;
      flex: 1 1 auto;

      // flexbox prevents any flex elements from being smaller than its content this breaks the layout
      // when there are long code examples (like with <pre />). `min-width: 0` fixes that behavior
      min-width: 0;
      overflow-wrap: break-word;
      padding: 30px 31px; // 31px to match other pages
      word-break: break-word;

      pre {
        overflow-wrap: normal;
        word-break: normal;
      }
    }

    // anchor icon
    .heading {
      &-anchor-icon {
        opacity: 0.5;

        &:hover,
        &:active,
        &:focus {
          color: var(--color-link-primary);
          opacity: 1 !important;
        }

        &:focus {
          border-radius: var(--border-radius);
          outline: 1px auto var(--color-link-primary);
          outline-offset: 2px;
        }
      }
    }
  }

  .markdown-body {
    table {
      table-layout: var(--table-layout, #{$table-layout});
      word-break: normal;
    }

    .variable-underline {
      border-bottom: 1px dotted #333;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
