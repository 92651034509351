@import '~@core/styles/mixins/dark-mode';

.App {
  .rm-SuggestedEdits,
  .rm-SuggestionDiff,
  .rm-Guides,
  .rm-ReferenceMain,
  .rm-Changelog,
  .rm-Discuss,
  .rm-CustomPage {
    /* The worst dark mode
    * Preferrably, we’d set this at the CodeMirror component, but we just couldn’t figure it out after hella days
    * Just gonna hard-code this theme via CSS for now…
    * This is a copy/paste of the CodeMirror theme for palenight—which is why some rules are disabled
    */
    /* stylelint-disable no-duplicate-selectors */
    /* stylelint-disable selector-max-compound-selectors */
    @include dark-mode {
      .rdmd-code {
        .CodeMirror {
          background-color: #292d3e;
        }

        .CodeMirror-gutters {
          background: #292d3e;
        }
      }

      .CodeEditor,
      .rdmd-code {
        .CodeMirror {
          color: #bebcd7;
        }

        .CodeMirror-gutters {
          border: 0;
          color: #9091B2;
        }

        .CodeMirror-guttermarker,
        .CodeMirror-guttermarker-subtle,
        .CodeMirror-linenumber {
          color: #9091B2;
        }

        .CodeMirror-cursor {
          border-left: 1px solid #fc0;
        }

        div.CodeMirror-selected {
          background: rgba(113, 124, 180, 0.2);
        }

        .CodeMirror-focused div.CodeMirror-selected {
          background: rgba(113, 124, 180, 0.2);
        }

        .CodeMirror-line::selection,
        .CodeMirror-line > span::selection,
        .CodeMirror-line > span > span::selection {
          background: rgba(128, 203, 196, 0.2);
        }

        .CodeMirror-line::selection,
        .CodeMirror-line > span::selection,
        .CodeMirror-line > span > span::selection {
          background: rgba(128, 203, 196, 0.2);
        }

        .CodeMirror-activeline-background {
          background: rgba(0, 0, 0, 0.5);
        }

        .cm-keyword {
          color: #d7b2f0;
        }

        .cm-operator {
          color: #89ddff;
        }

        .cm-variable-2 {
          color: #eff;
        }

        .cm-variable-3,
        .cm-type {
          color: #f07178;
        }

        .cm-builtin {
          color: #ffcb6b;
        }

        .cm-atom {
          color: #f78c6c;
        }

        .cm-number {
          color: #ff5370;
        }

        .cm-def {
          color: #82aaff;
        }

        .cm-string {
          color: #c3e88d;
        }

        .cm-string-2 {
          color: #f07178;
        }

        .cm-comment {
          color: #9091B2;
        }

        .cm-variable {
          color: #f07178;
        }

        .cm-tag {
          color: #ff5370;
        }

        .cm-meta {
          color: #ffcb6b;
        }

        .cm-attribute {
          color: #d7b2f0;
        }

        .cm-property {
          color: #d7b2f0;
        }

        .cm-qualifier {
          color: #decb6b;
        }

        .cm-variable-3,
        .cm-type {
          color: #decb6b;
        }

        .cm-error {
          background-color: #ff5370;
          color: rgba(255, 255, 255, 1);
        }

        .CodeMirror-matchingbracket {
          color: white !important;
          text-decoration: underline;
        }
      }
    }
  }
}
