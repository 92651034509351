// NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO
// └> This SCSS patches missing styles from the hub2 Stylus removal.

// Core Partials
//
@import '@core/styles/mixins/dark-mode';
@import '@core/styles/mixins/ngz';
@import '@core/styles/mixins/spinner';
@import '~@core/styles/vars';

// Icon Assets
//
@import '~@readme/hub/public/css/fontawesome-all.min.css';
@import '~@readme/hub/public/css/readme-icons.css';

// Backwards compatibility for Font Awesome 4
.fa:not(.fad, .fa-duotone) {
  font-weight: 400; // assume regular weight
}

// Hub2 Stylus Root Styles
//
body {
  color: #4c555a;
  font-family: var(--font-family);
  margin: 0;
}

a {
  text-decoration: none;
}

hr {
  border: solid var(--color-border-default);
  border-width: 1px 0 0;
}

.btn {
  @include rm-btn;
}

.glossary-tooltip {
  @include glossary-tooltip;
}

// Hub2 Stylus Quick Fixes
//
.ng-cloak,
.ng-hide {
  display: none;
}

.health-check-notification {
  display: none;
}

.hub-container,
#content-container {
  max-width: var(--container, 1140px);
  margin: 0 auto;
}

.markdown-body {
  &[style*='31px'] {
    // override inline styles :puke:
    margin-left: 0 !important;
  }
  > .lightbox {
    display: block;
  }
  .lightbox {
    &.open {
      .lightbox-inner {
        min-height: 100vh;
        min-width: 100vw;
      }
      img {
        cursor: zoom-out;
        margin: 0 auto !important;
      }
      figcaption {
        display: none;
      }
    }
    &.closed img {
      cursor: zoom-in;
    }
  }
  // Correctly align tutorial tiles on left and right float
  .TutorialTile-link[align] {
    &[align='right'] {
      float: right;
      margin-left: 0.75rem;
      margin-top: 0; //collapse margin on float - the non-floated tiles do this automatically

      // remove margin-top from all subsequent tutorialTiles on float due to .markdown-body:first-child having 0 margin-top
      &:is(:first-child) ~ .TutorialTile-link {
        margin-top: 0;
      }
    }
    &[align='left'] {
      float: left;
      margin-right: 0.75rem;
      margin-top: 0; //collapse margin on float - the non-floated tiles do this automatically

      // remove margin-top from all subsequent tutorialTiles on float due to .markdown-body:first-child having 0 margin-top
      &:is(:first-child) ~ .TutorialTile-link {
        margin-top: 0;
      }
    }
  }
}

#hub-search-results {
  .modal-backdrop {
    background: rgba(black, 0.1);
    box-sizing: border-box;
    display: flex;
    height: 100vh;
    left: 0;
    padding: $md;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10003; // toolbar is 10002, banners are 10001
  }

  &:not(.hub-search-results-active) .modal-backdrop {
    display: none;
  }
}

.rm-Header-bottom-link {
  box-sizing: border-box;
}

footer {
  clear: both;
  flex: 0 0 auto;
}

.rm-Banners {
  bottom: 0;
  position: sticky;
  z-index: 10001;
}

.hub-footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 10001;

  .hub-notification {
    align-items: center;
    background: var(--slate);
    border: 0;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 10px 15px;

    &[href]:hover,
    &[href]:focus,
    &[href]:active {
      background: darken($slate, 25%);
      color: #fff;
      cursor: pointer;
    }

    i {
      font-size: 17px;
      margin-right: 10px;
    }

    &-emoji {
      margin-right: 10px;
    }

    + .hub-notification {
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
  }

  .health-check-notification {
    display: none;

    .health-check-notification-link {
      color: #fff;
      cursor: pointer;
      display: none;
      flex: 0 0 auto;
      margin-left: 5px;
      text-decoration: underline;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

#hub-error {
  margin: 0;
  padding: 150px 0 0;
  i.icon {
    text-align: center;
    display: block;
    font-size: 80px;
    color: var(--gray70);
    margin-bottom: 30px;
  }
  h2 {
    font-weight: var(--font-weight-normal);
    text-align: center;
    color: var(--color-text-default);
    span {
      color: var(--color-text-minimum);
    }
  }
  pre code {
    background: #f9f9f9;
    display: block;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 50px;
  }
}

// NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO • NG-ZERO
